<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      top="3%"
      center
      :modal="false"
      :before-close="handleClose">
    <!--  <div class="father">-->
    <!--    <div class="loader"></div>-->
    <div class="logo_header" v-viewer>
      <img class="logo_img" v-for="(item,index) in List.list" :key="index" :src="item">
    </div>
    <!--    <span>这是一段信息</span>-->
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      <!--&lt;!&ndash;    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>&ndash;&gt;-->
  </span>
    <!--    <div style="margin-top: 10%">-->
    <!--      <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>-->
    <!--    </div>-->
  </el-dialog>
  <!--  </div>-->
</template>

<script>
export default {
  name: "cooperation_father",
  data() {
    return {
      title:'',
      List:[],
      dialogVisible: false,
    };
  },
  mounted() {
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(res => {
            console.log(res)
            done();
          })
          .catch(()=> {});
    },
  }
};
</script>

<style scoped lang="less">
.father{
  width: 80%;
  height: 80%;
  background-color: #fff;
  position: fixed;
  top: 10%;
  left: 10%;
}
/deep/ .el-dialog {
  background: #0c2555;
  border: 0;
  height: 80%;
}
/deep/ .el-dialog__title{
  color: #fff!important;
  font-size: 24px!important;
  letter-spacing: 2px;
}
/deep/ .el-dialog__body{
  height: 85%;
}
.logo_header{
  //width: 95%;
  margin: 0 auto;
  height: 100%;
  //padding-left: 5%;
  //overflow-y: scroll;
  display: flex;
  //justify-content: space-around;
  flex-wrap: wrap;
  .logo_img{
    width: 15%;
    margin-left: 4%;
    height: 30%;
    //margin-right: 3%;
    //margin-bottom: 2%;
  }
}
.loader{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}
</style>